
<mat-card class="example-card w-100">
  <div>
   <p><u>Homepage > Riders</u></p>
  </div>
</mat-card>
<!--Add Email Card-->
<mat-card class="example-card w-100 mt-2 shadow-none">
  <div class="d-flex">
    <mat-form-field class="example-chip-list mr-2" appearance="fill">
      <mat-label>Add emails to list</mat-label>
      <mat-chip-list #chipList aria-label="Email selection">
        <mat-chip *ngFor="let email of emails" [selectable]="selectable"
                 [removable]="removable" (removed)="remove(email)">
          {{email}}
          <button matChipRemove *ngIf="removable">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input placeholder="New email..."
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
    </mat-form-field>

    <mat-form-field appearance="fill" class="mr-2">
      <mat-label>Select Station</mat-label>
      <mat-select [(value)]="selectedPlace">
        <mat-option *ngFor="let place of places" [value]="place.pid">
          {{place.place_name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>
    
  </div>
  <p class="text-danger">{{error}}</p>
  <button mat-raised-button color="primary" (click)="addUsers()">Submit</button>
</mat-card>
<!--Email List Card-->
<mat-card class="w-100 mt-2 p-0">
  <mat-form-field appearance="outline" class="mx-2 mt-1">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100 shadow-none">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="font-blue"> Name </th>
      <td mat-cell *matCellDef="let element" class="text-capitalize font-blue font-weight-bold h6"><span class="d-flex justify-center"><mat-icon class="mr-2">directions_bike</mat-icon> {{element.email.slice(0, element.email.indexOf('@'))}} </span>  </td>
    </ng-container>

    <!-- Weight email -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <!-- Symbol status -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element; let i = index" class="text-capitalize">
        <mat-form-field appearance="outline">
          <mat-label>Select an option</mat-label>
          <mat-select [value]="data[i].status" (selectionChange)="selectStatus($event.value, data[i].email)">
            <mat-option value="active">active</mat-option>
            <mat-option disabled value="to activate">to activate</mat-option>
            <mat-option value="hold">hold</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <!-- Symbol status -->
    <ng-container matColumnDef="user_id">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
          <mat-icon>more</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="deleteRider(element.email)">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

</mat-card>
