export const environment = {
  firebase: {
    apiKey: "AIzaSyAOGVOZZ5vWEzLHJDmT9LhDeC9O6SQzRyc",
    authDomain: "evolvodev-4bfe3.firebaseapp.com",
    projectId: "evolvodev-4bfe3",
    storageBucket: "evolvodev-4bfe3.appspot.com",
    messagingSenderId: "821921832634",
    appId: "1:821921832634:web:1ffcd895bd6fdb32772c83"
  },
  production: false,
  mapbox: {
    accessToken:'pk.eyJ1IjoibWFnaWM0MDkiLCJhIjoiY2xmbWw2eHp2MGQ1cTNwcnZsb2ZqeWdmaiJ9.h7BcK63Zh4pR8yqYPoVxlg'
  }
};
