<div class="d-flex align-items-center justify-content-center w-100 h-100 bg-danger" >
  <div class="d-flex flex-column w-75 h-50 bg-light align-items-center  justify-content-center shadow-xl border border-warning p-3" style="border-radius: 20px;">
    <div class="h1 fw-bold">Welcome!</div>
    <p class="text-justify">We are excited to have you get started, Your email id is verified you can continue using evolvo app with currently used information.</p>
    <img src="../../assets/logo_evolvo.svg" height="100px">

    <div class="d-flex flex-md-row flex-column align-items-center justify-content-center">
      <a class="btn btn-google" href="https://play.google.com/store/apps/details?id=it.evolvo" title="Google Play">Google Play</a>

      <a href="https://apps.apple.com/gb/app/evolvo-mobility-solutions/id1596488056" target="_blank" class="market-btn apple-btn" role="button">
        <span class="market-button-subtitle">Download on the</span>
        <span class="market-button-title">App Store</span>
      </a>
    </div>
  </div>
</div>
