import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-white-list',
  templateUrl: './white-list.component.html',
  styleUrls: ['./white-list.component.scss']
})
export class WhiteListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
