<div class="bg-solid-light w-100" style="height: 100%;">
  <div class="d-flex align-items-center justify-content-center w-100 h-100">
    <div class="d-flex">
      <div class="bg-white px-5 py-3 rounded-left">
      <img src="../../assets/logo_evolvo.svg" class="my-2" alt="" height="40px">
        <h2 class="m-0">Login</h2>
        <p>Please enter all the details</p>
        <form [formGroup]="loginFormGroup" (ngSubmit)="onSubmit(loginFormGroup)" class="example-form d-flex flex-column">
          <mat-form-field class="example-full-width">
            <mat-label>UserName</mat-label>
            <input formControlName="user" matInput placeholder="xxx@xxx.com" value="">
            <mat-icon matPrefix class="mr-2">person</mat-icon>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Password</mat-label>
            <input type="password" formControlName="password" matInput placeholder="****">
            <mat-icon matPrefix class="mr-2">lock</mat-icon>
          </mat-form-field>
          <div class="d-flex">
            <a href="#">Forgot Password ?</a>
            <mat-slide-toggle formControlName="isAdmin" color="primary" class="ml-2">Admin</mat-slide-toggle>
          </div>
        <button mat-raised-button color="primary" class="w-100 m-3">Login</button>
        <p class="text-success" *ngIf="isLogin">Login Success</p>
        <p class="text-danger" *ngIf="!isLogin && isLogin != null">{{logStatus}}</p>
        </form>
      </div>


      <div class="bg-pattren p-5 rounded-right overflow-hidden">
        <div class="bg-trans p-4 text-light">
          <h1>Very good works are <br> waiting for you</h1>
          <h2>Login Now <span><mat-icon>radio_button_checked</mat-icon></span></h2>
          <img src="../../assets/svg/undraw_biking_kc-4-f.svg" height="200px">
        </div>
      </div>
    </div>
  </div>
</div>

