import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bigload',
  templateUrl: './bigload.component.html',
  styleUrls: ['./bigload.component.scss']
})
export class BigloadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
