<div class="bg-solid-light w-100 h-100">
  <div class="d-flex align-items-center justify-content-center w-100 h-100">
    <div class="d-flex flex-column flex-md-row flex-column-reverse">
      <div class="bg-white px-5 py-3 rounded-left">
      <img src="../../assets/logo_evolvo.svg" class="my-2" alt="" height="40px">
        <h2 class="m-0">Signup</h2>
        <p>Please enter all the details</p>
<!--         <button mat-raised-button (click)="isLinear = !isLinear" id="toggle-linear">
          {{!isLinear ? 'Enable linear mode' : 'Disable linear mode'}}
        </button> -->
        <mat-stepper orientation="vertical" [linear]="isLinear" #stepper>
          <mat-step [stepControl]="firstFormGroup">
            <form [formGroup]="firstFormGroup" (ngSubmit)="onSubmit(firstFormGroup)">
              <ng-template matStepLabel>Personal Details</ng-template>
                <div class="d-flex flex-wrap">
                  <mat-form-field appearance="outline" class="mx-1">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="Last name, First name" formControlName="pname" required>
                    <mat-icon matPrefix>person</mat-icon>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>LastName</mat-label>
                    <input matInput placeholder="Last name, First name" formControlName="plname" required>
                  </mat-form-field>
                </div>
                <div class="d-flex flex-wrap">
                  <mat-form-field appearance="outline" class="mx-1">
                    <mat-label>Phone No</mat-label>
                    <input matInput placeholder="Last name, First name" formControlName="pnumber" required>
                    <mat-icon matPrefix>phone</mat-icon>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>Mail</mat-label>
                    <input matInput placeholder="Last name, First name" formControlName="pmail" required>
                    <mat-icon matPrefix>mail</mat-icon>
                  </mat-form-field>
                </div>
                <div class="d-flex flex-wrap">
                  <mat-form-field appearance="outline" class="mx-1">
                    <mat-label>Password</mat-label>
                    <input type="password" matInput placeholder="Password" formControlName="password" required>
                    <mat-icon matPrefix>visibility_off</mat-icon>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="mx-1">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput placeholder="Password" formControlName="confirm_password" required>
                    <mat-icon matPrefix>visibility</mat-icon>
                  </mat-form-field>
                </div>
              <div>
                <button mat-button matStepperNext>Next</button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="secondFormGroup">
            <form [formGroup]="secondFormGroup" (ngSubmit)="onSubmit(secondFormGroup)">
              <ng-template matStepLabel>Company Details</ng-template>
                <div class="d-flex flex-wrap">
                  <mat-form-field appearance="outline" class="mx-2">
                    <mat-label>Company Name</mat-label>
                    <input matInput formControlName="cname" placeholder="Ex. 1 Main St, New York, NY"
                           required>
                    <mat-icon matPrefix>store</mat-icon>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>Domain</mat-label>
                    <input matInput formControlName="cdomain" placeholder="Ex. 1 Main St, New York, NY"
                           required>
                    <mat-icon matPrefix>domain_verification</mat-icon>
                  </mat-form-field>
                </div>
                <div class="d-flex flex-wrap">
                  <mat-form-field appearance="outline" class="mx-2">
                    <mat-label>Mail</mat-label>
                    <input matInput formControlName="cmail" placeholder="Ex. 1 Main St, New York, NY"
                           required>
                    <mat-icon matPrefix>mail</mat-icon>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>IBAN</mat-label>
                    <input matInput formControlName="iban" placeholder="Ex. 1 Main St, New York, NY"
                           required>
                    <mat-icon matPrefix>account_balance_wallet</mat-icon>
                  </mat-form-field>
                </div>
                <div class="d-flex flex-wrap">
                  <mat-form-field appearance="outline" class="mx-2">
                    <mat-label>p.iva</mat-label>
                    <input matInput formControlName="piva" placeholder="Ex. 1 Main St, New York, NY"
                           required>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>SDI</mat-label>
                    <input matInput formControlName="sdi" placeholder="Ex. 1 Main St, New York, NY"
                           required>
                  </mat-form-field>
                </div>
              <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button matStepperNext>Next</button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="thirdFormGroup">
            <form [formGroup]="thirdFormGroup" (ngSubmit)="onSubmit(thirdFormGroup)">
              <ng-template matStepLabel>Address</ng-template>
                <div class="d-flex flex-wrap">
                  <mat-form-field appearance="outline" class="mx-2">
                    <mat-label>Address</mat-label>
                    <textarea matInput formControlName="address" placeholder="Ex. 100 Main St"></textarea>
                    <mat-icon matPrefix>home</mat-icon>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>City</mat-label>
                    <input matInput formControlName="city" placeholder="Ex. 1 Main St, New York, NY"
                           required>
                    <mat-icon matPrefix>location_city</mat-icon>
                  </mat-form-field>
                </div>
                <div class="d-flex flex-wrap">
                  <mat-form-field appearance="outline" class="mx-2">
                    <mat-label>State</mat-label>
                    <input matInput formControlName="state" placeholder="Ex. 1 Main St, New York, NY"
                           required>
                    <mat-icon matPrefix>apartment</mat-icon>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>Postal Code</mat-label>
                    <input matInput formControlName="pcode" #postalCode maxlength="5" placeholder="Ex. 94105">
                    <mat-icon matPrefix>local_post_office</mat-icon>
                  </mat-form-field>
                </div>
              <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button matStepperNext>Next</button>
              </div>
            </form>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>Done</ng-template>
            <p>You are now done.</p>
            <div>
              <button mat-button matStepperPrevious class="">Back</button>
              <button mat-raised-button color="primary" (click)="submitForm()">Submit</button>
            </div>
          </mat-step>
        </mat-stepper>
      </div>
      <div class="bg-pattren p-5 rounded-right overflow-hidden">
        <div class="bg-trans p-4 text-light">
          <h1>Very good works are <br> waiting for you</h1>
          <h2>Login Now <span><mat-icon>radio_button_checked</mat-icon></span></h2>
          <img src="../../assets/svg/undraw_biking_kc-4-f.svg" height="200px">
        </div>
      </div>
    </div>
  </div>
</div>
