<mat-card class="example-card w-100">
  <div>
   <p><u>Homepage > Pricing</u></p>
  </div>
  </mat-card>

  <div class="pt-5 pl-5">
    <h1 class="m-0">Pricing</h1>
    <h6>Manage your price and billing details here</h6>
  </div>
  <div class="container row w-100 mx-1">
    <mat-card *ngFor="let price of priceList" class="col-12 col-lg-4 my-1 mx-2 hover-overlay">
      <div class="d-flex" style="height: 70%;">
        <div class="col-8">
          <div class="">
            <p class="h6"><span class="h4 text-capitalize">{{price.name}} </span>per/hour</p>
            <p class="badge badge-pill badge-success">{{price.status}}</p>
          </div>
          <h6>You can change your pricing plan</h6>
        </div>
        <div class="col-4 px-0">
          <span><span class="h3 mat-black">€ {{price.price}}</span> p/h</span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="w-100 d-flex justify-content-end mt-2">
        <button mat-stroked-button color="primary" (click)="editPrice(price.id)" class="rounded-button bg-white">Edit</button>
      </div>
    </mat-card>
    <mat-card *ngIf="priceList.length < 2" (click)="addPriceList()" class="col-12 col-lg-2 d-flex hover-overlay align-items-center justify-content-center" style="min-height:20vh;">
      <mat-icon style="font-size: 40px; margin-right: 25px;margin-left: 10px;">add_circle</mat-icon>
    </mat-card>
<!--     <mat-card class="w-30 mx-2">
      <div class="d-flex">
        <div class="col-8">
          <div class="">
            <span class="h4">Plan-1 per/hour</span>
            <span class="badge badge-pill badge-secondary">active</span>
          </div>
          <h6>You can change your pricing plan</h6>
        </div>
        <div class="col-4">
          <span><span class="h3 mat-black">€ 35</span> p/h</span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="w-100 d-flex justify-content-end mt-2">
        <button mat-button color="primary" class="rounded-button mx-2">Activate</button>
        <button mat-stroked-button color="primary" class="rounded-button">Edit</button>
      </div>
    </mat-card> -->
  </div>

  <div class="pt-5 pl-5">
    <h1 class="m-0">Plans</h1>
    <h6>Manage your plan details here</h6>
  </div>
  
  <div class="container row w-100 mx-1">
    <mat-card *ngFor="let reservationType of reservationTypes" class="col-12 my-1 mx-2 hover-overlay">
      <mat-card-title>{{reservationType.name}}</mat-card-title>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Days</th>
              <th>Start</th>
              <th>End</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let timeslot of reservationType.timeslot">
              <td>{{timeslot.name}}</td>
              <td>{{timeslot.days}}</td>
              <td>{{timeslot.start}}</td>
              <td>{{timeslot.end}}</td>
              <td>€ {{timeslot.price}}</td>
            </tr>
          </tbody>
        </table>
        <mat-card-actions align="end">
          <button mat-stroked-button color="primary" class="rounded-button bg-white">Edit</button>
        </mat-card-actions>
    </mat-card>
  </div>
