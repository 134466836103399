<div class="d-flex flex-column position-relative mat-black align-items-center justify-content-center rounded" style="height: 120px;">
  <div class="column">
    <mat-icon class="position-absolute mr-2" style="top: 8px; left: 30px;">{{ dashCardData.icon }}</mat-icon>
    <p class="font-weight-normal h4">{{ dashCardData.dasTitle }}</p>
  </div>
  <div class="d-flex align-items-center flex-col">
    <div class="col-4">
      <p class="font-weight-bold h2" style="color: #9a69ef;">{{ dashCardData.data }}</p>
    </div>
    <div class="col-8">
      <p>{{ dashCardData.description }}</p>
    </div>
  </div>
</div>
