import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.scss']
})
export class ActivateUserComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
