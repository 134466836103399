<mat-card class="example-card w-100">
  <div>
   <p><u>Homepage > Manager</u></p>
  </div>
</mat-card>
<button mat-raised-button color="primary" class="mx-2 my-3" (click)="addManager()">Add Manager</button>
<mat-card class="example-card w-100">
  <mat-form-field appearance="outline">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="name,mail or status" #input>
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>

  <table mat-table [dataSource]="data" class="mat-elevation-z8 w-100 shadow-none">

    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element" class="font-blue font-weight-bold h6"><span class="d-flex justify-center"><mat-icon class="mr-2">account_circle</mat-icon> {{element.name}} </span></td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="lname">
      <th mat-header-cell *matHeaderCellDef>Lname</th>
      <td mat-cell *matCellDef="let element" class="font-blue font-weight-bold h6"> {{element.lname}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element"> {{element.status}} </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
          <mat-icon>more</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="deleteRider(element.email)">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

</mat-card>
