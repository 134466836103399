<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="data">
    <th mat-header-cell *matHeaderCellDef> Data </th>
    <td mat-cell *matCellDef="let element"> {{element.data}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="time">
    <th mat-header-cell *matHeaderCellDef> Time </th>
    <td mat-cell *matCellDef="let element"> {{element.time}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

