<mat-card class="example-card w-100 mb-3">
  <div>
   <p><u>Homepage > Bikes</u></p>
  </div>
  </mat-card>
  <div *ngIf="userStatus == 'admin'" class="w-100 d-flex justify-content-end mb-2 pr-3">
    <button mat-raised-button color="primary" (click)="openPopup('new')">Add Bike</button>
  </div>
  <table mat-table
       [dataSource]="ELEMENT_DATA" multiTemplateDataRows
       class="mat-elevation-z8">
  <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
    <th mat-header-cell *matHeaderCellDef> {{column}} </th>
    <td mat-cell *matCellDef="let element" class="w-25">
      <span
      [ngClass]=
      "{
        'text-light bg-dark p-1 px-2 rounded-pill font-weight-light': column == 'bike_name',
        'font-weight-bold': column == 'bike_status',
        'text-danger': element[column] == 'maintainance',
        'text-success': element[column] == 'active',
        'text-info': element[column] == 'reserved',
        'text-primary': element[column] == 'available',
        'text-muted': column == 'bike_user',
        'text-capitalize': true
      }"
      > {{element[column]}}
    </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
      </button>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
      <div class="example-element-detail d-flex"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="example-element-diagram col-4">
          <div class="d-flex justify-content-around">
            <img src="https://www.bcaa.com/-/media/evo/images/evolve/evolve-bike-png-2.ashx" height="80" loading="lazy">
            <div class="">
              <div class="mb-2 font-weight-bold">
                Key : ***** <br>
                Seriel : {{element.seriel}} <br>
                Mac : {{element.mac_address}}
              </div>
            </div>
          </div>
        </div>
        <div class="example-element-description d-flex w-100 align-items-center justify-content-end opacity-2">
          <button mat-raised-button (click)="unlockBike(element.device_id,element.evokey)" [ngClass]="unlockStatus == 'failed' ? 'btn-failed' : 'btn-unlock' " class="rounded-pill">{{unlockStatus}}</button>
          <button mat-stroked-button (click)="openPopup(element)" color="primary" class="rounded-pill mx-3">Edit</button>
          <button *ngIf="userStatus == 'admin'" mat-raised-button (click)="deleteBike(element.id)" color="warn" class="rounded-pill">Delete</button>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>

